import React from "react";
import { Flex, Heading, Link, Truncate } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductCardTitle = function ProductCardTitle(_ref) {
  var productId = _ref.productId,
      title = _ref.title,
      url = _ref.url,
      onClick = _ref.onClick;
  return ___EmotionJSX(Flex, {
    position: "relative",
    flexShrink: 0,
    px: 4,
    color: "color.text",
    fontWeight: "normal",
    onClick: onClick
  }, ___EmotionJSX(Truncate, {
    lines: 2
  }, ___EmotionJSX(Heading.h2, {
    m: 0,
    color: "currentColor",
    fontSize: "base"
  }, ___EmotionJSX(Link, {
    color: "currentColor",
    hoverColor: "currentColor",
    href: url,
    title: "View ".concat(title),
    "data-product-id": productId
  }, title))));
};