import { getEnvConfig } from "@catchoftheday/env-config";
import { dollarsToCents, formatPrice, queryString } from "@catchoftheday/utilities";
// Add query params to search product url
export var getSearchProductUrl = function getSearchProductUrl(product) {
  var id = product.id,
      sourceId = product.sourceId,
      sourceType = product.sourceType,
      sourcePosition = product.sourcePosition,
      offerId = product.offerId,
      url = product.url,
      trackEventToken = product.trackEventToken;
  var searchParams = queryString.stringify({
    pid: id,
    sid: sourceId,
    st: sourceType,
    sp: sourcePosition,
    oid: offerId,
    srtrev: trackEventToken
  });
  return "".concat(url).concat(url.includes("?") ? "&" : "?").concat(searchParams);
};
export var mapSearchResultItem = function mapSearchResultItem(product) {
  var id = product.id,
      title = product.title,
      brand = product.brand,
      imageLocation = product.imageLocation,
      images = product.images;
  return {
    id: id.toString(),
    title: title,
    brand: brand ? {
      name: brand.name,
      slug: brand.url || ""
    } : null,
    url: getSearchProductUrl(product),
    priceTag: mapPriceTag(product),
    assets: {
      hero: {
        url: "".concat(getEnvConfig().COTD_WEBSITE_CDN_URL, "/").concat(imageLocation),
        altText: (images || [])[0].alt || ""
      }
    }
  };
}; // The following mapper function has been copied from:
// search-app/src/utilities/search/search.utilities.resultToProductItem.ts
// This hasn't been imported directly as it would lead to a circular dependency.

export var mapPriceTag = function mapPriceTag(product) {
  var showPromotionPrice = product.showPromotionPrice,
      showPromotionTimer = product.showPromotionTimer,
      promotionDescription = product.promotionDescription,
      priceDisplayType = product.priceDisplayType,
      selectedOfferDiscountEndDate = product.selectedOfferDiscountEndDate,
      wasPrice = product.wasPrice,
      sellPrice = product.sellPrice,
      showSavingsLabel = product.showSavingsLabel,
      savingsLabel = product.savingsLabel,
      showClubCatchPrice = product.showClubCatchPrice,
      clubCatchPrice = product.clubCatchPrice,
      clubCatchExclusive = product.clubCatchExclusive,
      retailPrice = product.retailPrice,
      clearancePriceLabel = product.clearancePriceLabel,
      showFromLabel = product.showFromLabel;
  return {
    priceDiscount: showPromotionPrice ? {
      label: priceDisplayType,
      percentageLabel: showPromotionTimer ? null : promotionDescription,
      endDate: showPromotionTimer && selectedOfferDiscountEndDate ? selectedOfferDiscountEndDate : null
    } : null,
    priceLabel: {
      wasPrice: showPromotionPrice && wasPrice ? {
        amount: dollarsToCents(wasPrice)
      } : null,
      price: {
        amount: sellPrice ? dollarsToCents(sellPrice) : 0
      },
      saving: showSavingsLabel ? {
        label: "Save",
        savingAmount: {
          amount: dollarsToCents(savingsLabel)
        }
      } : null
    },
    topLabel: function () {
      if (clearancePriceLabel) {
        return clearancePriceLabel;
      }

      if (showFromLabel && !showPromotionPrice) {
        return "From";
      }

      if (!showPromotionPrice && priceDisplayType) {
        return "".concat(priceDisplayType, " ").concat(formatPrice(dollarsToCents(retailPrice), false));
      }

      return null;
    }(),
    clubSellPrice: showClubCatchPrice ? {
      amount: dollarsToCents(clubCatchPrice)
    } : null,
    clubExclusive: !!clubCatchExclusive,
    enabled: true,
    retailPriceDisclaimer: null
  };
};